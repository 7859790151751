import revive_payload_client_72Ga4ozmwh from "/home/runner/work/web-frontend/web-frontend/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.4_eslint@8.57.1_ioredis@5.4.1_magicast@0.3_xjppn53dqcnpxnsopem3trqb44/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_EOynXxajm7 from "/home/runner/work/web-frontend/web-frontend/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.4_eslint@8.57.1_ioredis@5.4.1_magicast@0.3_xjppn53dqcnpxnsopem3trqb44/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_UOnZZqoXWn from "/home/runner/work/web-frontend/web-frontend/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.4_eslint@8.57.1_ioredis@5.4.1_magicast@0.3_xjppn53dqcnpxnsopem3trqb44/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_fAea850vZ4 from "/home/runner/work/web-frontend/web-frontend/node_modules/.pnpm/nuxt-site-config@2.2.18_magicast@0.3.5_rollup@4.24.0_vite@5.4.8_@types+node@22.7.4_sass@1.79._mvdqxbkzqt5rbo54akudbjiy2e/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import payload_client_W1oZ2BceeI from "/home/runner/work/web-frontend/web-frontend/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.4_eslint@8.57.1_ioredis@5.4.1_magicast@0.3_xjppn53dqcnpxnsopem3trqb44/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_AaT9xkvo0r from "/home/runner/work/web-frontend/web-frontend/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.4_eslint@8.57.1_ioredis@5.4.1_magicast@0.3_xjppn53dqcnpxnsopem3trqb44/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_wJc6zDnjoi from "/home/runner/work/web-frontend/web-frontend/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.4_eslint@8.57.1_ioredis@5.4.1_magicast@0.3_xjppn53dqcnpxnsopem3trqb44/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_22wMjiQMs9 from "/home/runner/work/web-frontend/web-frontend/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.4_eslint@8.57.1_ioredis@5.4.1_magicast@0.3_xjppn53dqcnpxnsopem3trqb44/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_E4JHtm2uoD from "/home/runner/work/web-frontend/web-frontend/node_modules/.pnpm/@pinia+nuxt@0.5.5_magicast@0.3.5_rollup@4.24.0_typescript@5.6.2_vue@3.5.11_typescript@5.6.2_/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/home/runner/work/web-frontend/web-frontend/.nuxt/components.plugin.mjs";
import prefetch_client_QN6xE78Mck from "/home/runner/work/web-frontend/web-frontend/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.4_eslint@8.57.1_ioredis@5.4.1_magicast@0.3_xjppn53dqcnpxnsopem3trqb44/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import switch_locale_path_ssr_qf2khzXOSG from "/home/runner/work/web-frontend/web-frontend/node_modules/.pnpm/@nuxtjs+i18n@8.5.5_magicast@0.3.5_rollup@4.24.0_vue@3.5.11_typescript@5.6.2_/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import i18n_mzKCcb0bwp from "/home/runner/work/web-frontend/web-frontend/node_modules/.pnpm/@nuxtjs+i18n@8.5.5_magicast@0.3.5_rollup@4.24.0_vue@3.5.11_typescript@5.6.2_/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import plugin_Huh8JJeGZ0 from "/home/runner/work/web-frontend/web-frontend/node_modules/.pnpm/dayjs-nuxt@2.1.11_magicast@0.3.5_rollup@4.24.0/node_modules/dayjs-nuxt/dist/runtime/plugin.js";
import plugin_CdMGBlxguE from "/home/runner/work/web-frontend/web-frontend/node_modules/.pnpm/@nuxtjs+apollo@5.0.0-alpha.14_magicast@0.3.5_rollup@4.24.0_typescript@5.6.2_vue@3.5.11_typescript@5.6.2_/node_modules/@nuxtjs/apollo/dist/runtime/plugin.mjs";
import apollo_craft_sclci9JHWi from "/home/runner/work/web-frontend/web-frontend/plugins/apollo-craft.ts";
import apollo_error_handler_a0IiUcX0mN from "/home/runner/work/web-frontend/web-frontend/plugins/apollo-error-handler.ts";
import crypto_IPXw5s4rs8 from "/home/runner/work/web-frontend/web-frontend/plugins/crypto.ts";
import dompurify_qvTkTTURiH from "/home/runner/work/web-frontend/web-frontend/plugins/dompurify.ts";
import sentry_client_shVUlIjFLk from "/home/runner/work/web-frontend/web-frontend/plugins/sentry.client.ts";
export default [
  revive_payload_client_72Ga4ozmwh,
  unhead_EOynXxajm7,
  router_UOnZZqoXWn,
  _0_siteConfig_fAea850vZ4,
  payload_client_W1oZ2BceeI,
  navigation_repaint_client_AaT9xkvo0r,
  check_outdated_build_client_wJc6zDnjoi,
  chunk_reload_client_22wMjiQMs9,
  plugin_vue3_E4JHtm2uoD,
  components_plugin_KR1HBZs4kY,
  prefetch_client_QN6xE78Mck,
  switch_locale_path_ssr_qf2khzXOSG,
  i18n_mzKCcb0bwp,
  plugin_Huh8JJeGZ0,
  plugin_CdMGBlxguE,
  apollo_craft_sclci9JHWi,
  apollo_error_handler_a0IiUcX0mN,
  crypto_IPXw5s4rs8,
  dompurify_qvTkTTURiH,
  sentry_client_shVUlIjFLk
]