export const ds_News_Entry = `
    fragment ds_News_Entry on ds_News_Entry {
        id
        uid
        title
        articleExcerpt
        category {
            id
            title
        }
        authorName
        authorPhoto {
            url @transform(width: 100, height: 100)
        }
        image {
            url
        }
        dateCreated
        articleContentBuilder {
            __typename
        }
    }
`