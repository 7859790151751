export const ds_Homepage_Entry = `
    fragment ds_Homepage_Entry on ds_Homepage_Entry {
        uid
        typeHandle
        popup {
            uid
        }
        preFooter {
            uid
        }
        contentBuilder {
            __typename
        }
        banner {
            ... on ds_CarouselBanner_Entry {
                id
                carouselBanner_ColorTheme
                carouselBanner_ColorPalette
                carouselBanner_Alignment
                carouselBanner_Slides {
                    ... on ds_CarouselBannerSlide_Entry {
                        carouselBanner_Heading
                        carouselBanner_Subheading
                        carouselBanner_Image {
                            id
                            url @transform(width: 1920, height: 1080)
                            focalPoint
                        }
                        carouselBanner_Button {
                            url
                            text
                            target
                            ... on carouselBanner_Button_Entry_LinkType {
                                buttonStyle
                            }
                            ... on carouselBanner_Button_Url_LinkType {
                                buttonStyle
                            }
                            ... on carouselBanner_Button_Custom_LinkType {
                                buttonStyle
                            }
                            ... on carouselBanner_Button_Email_LinkType {
                                buttonStyle
                            }
                            ... on carouselBanner_Button_Phone_LinkType {
                                buttonStyle
                            }
                        }
                    }
                }
            }
            ... on ds_VideoBanner_Entry {
                videoBanner_Video {
                    ... on ds_VideosSection_Entry {
                        videoUrl
                        videoType
                        videoFile {
                            url
                        }
                    }
                }
                videoBanner_Subheading
                videoBanner_Heading
                videoBanner_ColorTheme
                videoBanner_ColorPalette
                videoBanner_Alignment
                videoBanner_Button {
                    url
                    text
                    target
                    ... on videoBanner_Button_Entry_LinkType {
                        buttonStyle
                    }
                    ... on videoBanner_Button_Url_LinkType {
                        buttonStyle
                    }
                    ... on videoBanner_Button_Custom_LinkType {
                        buttonStyle
                    }
                    ... on videoBanner_Button_Email_LinkType {
                        buttonStyle
                    }
                    ... on videoBanner_Button_Phone_LinkType {
                        buttonStyle
                    }
                }
            }
        }
    }
`
