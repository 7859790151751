import { default as _91_46_46_46uri_93LCFzvqCSUsMeta } from "/home/runner/work/web-frontend/web-frontend/pages/[...uri].vue?macro=true";
import { default as indexBAXdbXdxHCMeta } from "/home/runner/work/web-frontend/web-frontend/pages/index.vue?macro=true";
import { default as searchibSVO7DIpzMeta } from "/home/runner/work/web-frontend/web-frontend/pages/search.vue?macro=true";
import { default as accordionzV2JyiOY2hMeta } from "/home/runner/work/web-frontend/web-frontend/pages/sections/accordion.vue?macro=true";
import { default as athletics_45calendarYpUxwD0E6JMeta } from "/home/runner/work/web-frontend/web-frontend/pages/sections/athletics-calendar.vue?macro=true";
import { default as button_45groupkzCsADRrjUMeta } from "/home/runner/work/web-frontend/web-frontend/pages/sections/button-group.vue?macro=true";
import { default as dividerowdPtMEVZ5Meta } from "/home/runner/work/web-frontend/web-frontend/pages/sections/divider.vue?macro=true";
import { default as events_45calendarxWsTlPLOU4Meta } from "/home/runner/work/web-frontend/web-frontend/pages/sections/events-calendar.vue?macro=true";
import { default as events_45listingTSKtEgN7WLMeta } from "/home/runner/work/web-frontend/web-frontend/pages/sections/events-listing.vue?macro=true";
import { default as feature_45tilesJ5NQXRCYYNMeta } from "/home/runner/work/web-frontend/web-frontend/pages/sections/feature-tiles.vue?macro=true";
import { default as formBLoowzZA1yMeta } from "/home/runner/work/web-frontend/web-frontend/pages/sections/form.vue?macro=true";
import { default as galleryuoXaZAKWiUMeta } from "/home/runner/work/web-frontend/web-frontend/pages/sections/gallery.vue?macro=true";
import { default as image_45and_45links_45columnsS0kCIRIPLoMeta } from "/home/runner/work/web-frontend/web-frontend/pages/sections/image-and-links-columns.vue?macro=true";
import { default as image_45and_45text_45accordionStXRGuLKtrMeta } from "/home/runner/work/web-frontend/web-frontend/pages/sections/image-and-text-accordion.vue?macro=true";
import { default as imagesp6VHgCiMN3Meta } from "/home/runner/work/web-frontend/web-frontend/pages/sections/images.vue?macro=true";
import { default as indexGeMX4Cv8YdMeta } from "/home/runner/work/web-frontend/web-frontend/pages/sections/index.vue?macro=true";
import { default as layoutwMP5u2SY6KMeta } from "/home/runner/work/web-frontend/web-frontend/pages/sections/layout.vue?macro=true";
import { default as linksq2QVmeX9p8Meta } from "/home/runner/work/web-frontend/web-frontend/pages/sections/links.vue?macro=true";
import { default as news_45listing4OfUInRJCqMeta } from "/home/runner/work/web-frontend/web-frontend/pages/sections/news-listing.vue?macro=true";
import { default as quoteDGFkZ4IqvuMeta } from "/home/runner/work/web-frontend/web-frontend/pages/sections/quote.vue?macro=true";
import { default as rich_45textQohbcuPELlMeta } from "/home/runner/work/web-frontend/web-frontend/pages/sections/rich-text.vue?macro=true";
import { default as social_45media_45feedQbauYEtMJwMeta } from "/home/runner/work/web-frontend/web-frontend/pages/sections/social-media-feed.vue?macro=true";
import { default as staff_45directory2g1i5qBD1bMeta } from "/home/runner/work/web-frontend/web-frontend/pages/sections/staff-directory.vue?macro=true";
import { default as tableIxpsONaWshMeta } from "/home/runner/work/web-frontend/web-frontend/pages/sections/table.vue?macro=true";
import { default as tabs7rVXRqYA05Meta } from "/home/runner/work/web-frontend/web-frontend/pages/sections/tabs.vue?macro=true";
import { default as tilesKxKTzmFLxeMeta } from "/home/runner/work/web-frontend/web-frontend/pages/sections/tiles.vue?macro=true";
import { default as videomoHypu999mMeta } from "/home/runner/work/web-frontend/web-frontend/pages/sections/video.vue?macro=true";
import { default as sectionsbZPZxuIu9qMeta } from "/home/runner/work/web-frontend/web-frontend/pages/sections.vue?macro=true";
import { default as component_45stubGJ6K5OzBBRMeta } from "/home/runner/work/web-frontend/web-frontend/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.4_eslint@8.57.1_ioredis@5.4.1_magicast@0.3_xjppn53dqcnpxnsopem3trqb44/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stubGJ6K5OzBBR } from "/home/runner/work/web-frontend/web-frontend/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.4_eslint@8.57.1_ioredis@5.4.1_magicast@0.3_xjppn53dqcnpxnsopem3trqb44/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "uri",
    path: "/:uri(.*)*",
    component: () => import("/home/runner/work/web-frontend/web-frontend/pages/[...uri].vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/home/runner/work/web-frontend/web-frontend/pages/index.vue")
  },
  {
    name: "search",
    path: "/search",
    component: () => import("/home/runner/work/web-frontend/web-frontend/pages/search.vue")
  },
  {
    name: sectionsbZPZxuIu9qMeta?.name,
    path: "/sections",
    component: () => import("/home/runner/work/web-frontend/web-frontend/pages/sections.vue"),
    children: [
  {
    name: "sections-accordion",
    path: "accordion",
    component: () => import("/home/runner/work/web-frontend/web-frontend/pages/sections/accordion.vue")
  },
  {
    name: "sections-athletics-calendar",
    path: "athletics-calendar",
    component: () => import("/home/runner/work/web-frontend/web-frontend/pages/sections/athletics-calendar.vue")
  },
  {
    name: "sections-button-group",
    path: "button-group",
    component: () => import("/home/runner/work/web-frontend/web-frontend/pages/sections/button-group.vue")
  },
  {
    name: "sections-divider",
    path: "divider",
    component: () => import("/home/runner/work/web-frontend/web-frontend/pages/sections/divider.vue")
  },
  {
    name: "sections-events-calendar",
    path: "events-calendar",
    component: () => import("/home/runner/work/web-frontend/web-frontend/pages/sections/events-calendar.vue")
  },
  {
    name: "sections-events-listing",
    path: "events-listing",
    component: () => import("/home/runner/work/web-frontend/web-frontend/pages/sections/events-listing.vue")
  },
  {
    name: "sections-feature-tiles",
    path: "feature-tiles",
    component: () => import("/home/runner/work/web-frontend/web-frontend/pages/sections/feature-tiles.vue")
  },
  {
    name: "sections-form",
    path: "form",
    component: () => import("/home/runner/work/web-frontend/web-frontend/pages/sections/form.vue")
  },
  {
    name: "sections-gallery",
    path: "gallery",
    component: () => import("/home/runner/work/web-frontend/web-frontend/pages/sections/gallery.vue")
  },
  {
    name: "sections-image-and-links-columns",
    path: "image-and-links-columns",
    component: () => import("/home/runner/work/web-frontend/web-frontend/pages/sections/image-and-links-columns.vue")
  },
  {
    name: "sections-image-and-text-accordion",
    path: "image-and-text-accordion",
    component: () => import("/home/runner/work/web-frontend/web-frontend/pages/sections/image-and-text-accordion.vue")
  },
  {
    name: "sections-images",
    path: "images",
    component: () => import("/home/runner/work/web-frontend/web-frontend/pages/sections/images.vue")
  },
  {
    name: "sections",
    path: "",
    component: () => import("/home/runner/work/web-frontend/web-frontend/pages/sections/index.vue")
  },
  {
    name: "sections-layout",
    path: "layout",
    component: () => import("/home/runner/work/web-frontend/web-frontend/pages/sections/layout.vue")
  },
  {
    name: "sections-links",
    path: "links",
    component: () => import("/home/runner/work/web-frontend/web-frontend/pages/sections/links.vue")
  },
  {
    name: "sections-news-listing",
    path: "news-listing",
    component: () => import("/home/runner/work/web-frontend/web-frontend/pages/sections/news-listing.vue")
  },
  {
    name: "sections-quote",
    path: "quote",
    component: () => import("/home/runner/work/web-frontend/web-frontend/pages/sections/quote.vue")
  },
  {
    name: "sections-rich-text",
    path: "rich-text",
    component: () => import("/home/runner/work/web-frontend/web-frontend/pages/sections/rich-text.vue")
  },
  {
    name: "sections-social-media-feed",
    path: "social-media-feed",
    component: () => import("/home/runner/work/web-frontend/web-frontend/pages/sections/social-media-feed.vue")
  },
  {
    name: "sections-staff-directory",
    path: "staff-directory",
    component: () => import("/home/runner/work/web-frontend/web-frontend/pages/sections/staff-directory.vue")
  },
  {
    name: "sections-table",
    path: "table",
    component: () => import("/home/runner/work/web-frontend/web-frontend/pages/sections/table.vue")
  },
  {
    name: "sections-tabs",
    path: "tabs",
    component: () => import("/home/runner/work/web-frontend/web-frontend/pages/sections/tabs.vue")
  },
  {
    name: "sections-tiles",
    path: "tiles",
    component: () => import("/home/runner/work/web-frontend/web-frontend/pages/sections/tiles.vue")
  },
  {
    name: "sections-video",
    path: "video",
    component: () => import("/home/runner/work/web-frontend/web-frontend/pages/sections/video.vue")
  }
]
  },
  {
    name: component_45stubGJ6K5OzBBRMeta?.name,
    path: "/sitemaps-1-sitemap.xml",
    component: component_45stubGJ6K5OzBBR
  },
  {
    name: component_45stubGJ6K5OzBBRMeta?.name,
    path: "/sitemap.xml",
    component: component_45stubGJ6K5OzBBR
  },
  {
    name: component_45stubGJ6K5OzBBRMeta?.name,
    path: "/ds_Events-sitemap.xml",
    component: component_45stubGJ6K5OzBBR
  },
  {
    name: component_45stubGJ6K5OzBBRMeta?.name,
    path: "/ds_Homepage-sitemap.xml",
    component: component_45stubGJ6K5OzBBR
  },
  {
    name: component_45stubGJ6K5OzBBRMeta?.name,
    path: "/ds_News-sitemap.xml",
    component: component_45stubGJ6K5OzBBR
  },
  {
    name: component_45stubGJ6K5OzBBRMeta?.name,
    path: "/ds_Pages-sitemap.xml",
    component: component_45stubGJ6K5OzBBR
  }
]