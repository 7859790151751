<template>
    <div
        v-if="active && alert"
        :data-theme="colorTheme"
        :data-mode="alert.alertBar_ColorPalette"
        class="fixed flex items-center bottom-0 inset-x-0 surface-background border-t border-subtle py-3 pl-8 pr-16 z-50"
    >
        <div
            class="flex w-full items-start font-body text-neutral-base text-base leading-base font-semibold"
            :class="{
                'justify-center': alert.alertBar_Alignment === 'centred',
                'justify-start': alert.alertBar_Alignment === 'left',
            }"
        >
            <DIcon
                v-if="alertItem.alertBarItem_Icon?.name"
                :icon="alertItem.alertBarItem_Icon.name"
                :icon-set="alertItem.alertBarItem_Icon.set"
                :type="alertItem.alertBarItem_Icon.type"
                class="mr-3 shape-brand-accent"
            />

            <p>
                {{ alertItem.alertBarItem_Text }}

                <NuxtLink
                    v-if="alertLink?.url"
                    :to="alertLink.url"
                    :target="alertLink.target"
                    class="inline underline underline-offset-4 ml-1"
                >
                    {{ alertLink.text }}
                </NuxtLink>
            </p>
        </div>

        <div class="absolute right-0 inset-y-0 flex items-center pr-6">
            <DButton
                theme="transparent"
                icon="x"
                icon-set="ph"
                @click="dismiss"
            />
        </div>

    </div>
</template>

<script setup>
    import { DButton, DIcon } from '@digistorm/spark'

    const { globalSets, globalTheme } = storeToRefs(useSiteStore())

    const active = ref(false)

    const alert = computed(() => {
        return find(globalSets.value, { name: 'Alert bar' })
    })

    const colorTheme = computed(() => {
        const alertTheme = get(alert.value, 'alertBar_ColorTheme')

        if (!isEmpty(alertTheme)) {
            return alertTheme
        }

        return globalTheme.value
    })

    const alertItem = computed(() => {
        return get(alert.value, 'alertBar_Items.0', {})
    })

    const alertRead = useCookie(`alert:${alertItem.value.uid}`)

    const alertLink = computed(() => {
        return get(alertItem.value, 'alertBarItem_Link.0', {})
    })

    const dismiss = () => {
        alertRead.value = true
        active.value = false
    }

    onMounted(() => {
        if (alertItem.value.uid && !alertRead.value) {
            active.value = true
        }
    })

</script>

<style lang="scss" scoped>

</style>