<template>
    <NuxtLayout>
        <NuxtLoadingIndicator />
        <NuxtPage />

        <AlertBar v-if="alert" />
    </NuxtLayout>
</template>

<script setup>
    import AlertBar from '~/components/shared/AlertBar.vue'

    const cookieCredentials = useCookie('cms_credentials')

    const { apiUrl, adminUrl, craftUrl, globalSets, themeCss } = storeToRefs(useSiteStore())

    const alert = computed(() => {
        return find(globalSets.value, { name: 'Alert bar' })
    })

    const addFavicon = () => {
        const logos = find(globalSets.value, { name: 'Logos'})
        const favicon = get(logos, 'logos_BrowserIcon[0].url')
        if (!favicon) {
            return
        }
        useHead({

            htmlAttrs: {
                lang: 'en'
            },
            link: [
                {
                    rel: 'icon',
                    type: 'image/x-icon',
                    href: favicon
                }
            ]
        })
    }


    onMounted(() => {
        const apiEndpoint = cookieCredentials.value?.endpoint
        apiUrl.value = apiEndpoint

        adminUrl.value = replace(apiEndpoint, '/api', '/admin')
        craftUrl.value = replace(apiEndpoint, '/api', '')

        addFavicon()
    })

    each(themeCss.value, (styles) => {
        useHead({
            style: [
                {
                    innerHTML: styles.css,
                    id: `site-theme-${styles.id}`,
                },
            ]
        })
    })

</script>