import { ApolloLink, ApolloError, createHttpLink, from } from '@apollo/client/core'
import { provideApolloClient } from '@vue/apollo-composable'
import { print } from 'graphql/language/printer'
import { CraftUtil } from '~/util/craft-util'
import { Observable } from 'zen-observable-ts'

let Sentry
let config = {
    endpoint: '',
    token: '',
}

export default defineNuxtPlugin({
    name: 'apollo-craft',
    async setup(nuxtApp) {
        const runtimeConfig = useRuntimeConfig()
        const route = useRoute()
        const cookieCredentials = useCookie('cms_credentials', {
            sameSite: 'none',
            secure: true,
        })

        if (import.meta.server && nuxtApp.ssrContext !== undefined) {
            Sentry = nuxtApp.ssrContext.event.context.$sentry
            // Get runtime config
            const runtimeConfig = nuxtApp.$config
            const headers = nuxtApp.ssrContext.event.node.req.headers

            config = CraftUtil.getServerAuthConfig(runtimeConfig, headers)

            cookieCredentials.value = { ...config }
        }

        if (import.meta.client) {
            Sentry = nuxtApp.vueApp.config.globalProperties.$sentry
            // Get the endpoint and token from the 'cms_credentials' cookie
            if (cookieCredentials.value !== undefined) {
                config.endpoint = cookieCredentials.value.endpoint
                config.token = cookieCredentials.value.token
            }
        }

        if (!config.endpoint || !config.token) {
            throw createError({
                statusCode: 500,
                statusMessage: 'Something went wrong',
                data: {
                    message: 'GraphQL endpoint or token missing.',
                },
            })
        }

        const makeSentryBreadcrumb = (operation) => {
            const definition = operation.query.definitions.find(
                (q) => q.kind === 'OperationDefinition',
            )

            return {
                type: 'http',
                level: 'info',
                category: `graphql.${definition.operation}`,
                data: {
                    url: operation.getContext().uri,
                    type: operation.query.definitions.find(defn => defn.operation).operation,
                    operationName: definition.name?.value,
                    query: definition.loc?.source?.body ?? print(definition),
                    variables: operation.variables,
                    fetchResult: null,
                },
            };
        }
        const addSentryBreadcrumb = (breadcrumbData) => {
            if (!runtimeConfig.public?.SENTRY_DSN) {
                console.log('GraphQL: type', breadcrumbData.data.type)
                console.log('GraphQL: name', breadcrumbData.data.operationName)
                console.log('GraphQL: query', breadcrumbData.data.query)
                console.log('GraphQL: variables', breadcrumbData.data.variables)
            } else {
                if (import.meta.server && nuxtApp.ssrContext !== undefined) {
                    Sentry = nuxtApp.ssrContext.event.context.$sentry
                    Sentry.addBreadcrumb(breadcrumbData)
                } else {
                    Sentry = nuxtApp.vueApp.config.globalProperties.$sentry
                    // TODO try adding breadcrumb to Sentry
                }
            }
        }

        const sentryLink = new ApolloLink((operation, forward) => {

            const breadcrumb = makeSentryBreadcrumb(operation)

            // While this could be done more simplistically by simply subscribing,
            // wrapping the observer in our own observer ensures we get the results
            // before they are passed along to other observers. This guarantees we
            // get to run our instrumentation before others observers potentially
            // throw and thus flush the results to Sentry.
            return new Observable((originalObserver) => {
                const subscription = forward(operation).subscribe({
                    next: (result) => {
                        if (result.errors && result.errors.length > 0) {
                            breadcrumb.level = 'error'
                            breadcrumb.data.error = new ApolloError({
                                graphQLErrors: result.errors,
                            })
                        }
                        breadcrumb.data.fetchResult = result

                        originalObserver.next(result);
                    },
                    complete: () => {
                        addSentryBreadcrumb(breadcrumb)

                        originalObserver.complete();
                    },
                    error: (error) => {
                        breadcrumb.level = 'error'
                        if ('result' in error) {
                            breadcrumb.data.fetchResult = result
                        }
                        addSentryBreadcrumb(breadcrumb)

                        originalObserver.error(error);
                    },
                });

                return () => {
                    subscription.unsubscribe();
                };
            })
        })

        const headers = {
            Authorization: `Bearer ${config.token}`,
        }

        if (route.query?.token) {
            headers['X-Craft-Token'] = route.query?.token
        }

        const httpLink = createHttpLink({
            uri: `${config.endpoint}`,
            headers,
        })

        nuxtApp.$apollo.defaultClient.setLink(from([
            sentryLink,
            httpLink,
        ]))

        provideApolloClient(nuxtApp.$apollo.defaultClient)
    },
})
