<template>
    <component
        :is="navigationComponent"
        :entries="navigation"
        :settings="settings"
        :has-banner="hasBannerImage"
    >
        <template #logo="navigationProps">
            <img v-if="navigationProps.sticky" :src="stickyHeaderLogo[0].url" :alt="`${variables?.siteName} logo`">
            <img v-else-if="!(isPremier && pageType === 'home')" :src="headerLogo[0].url" :alt="`${variables?.siteName} logo`">
        </template>
    </component>
</template>

<script setup>
    const pageType = inject('page-type', 'home')

    const { navigation, globalSets, digistormSettings, entry, variables } = storeToRefs(useSiteStore())

    const settings = computed(() => {
        const navigationSet = find(globalSets.value, { __typename: 'ds_Navigation_GlobalSet'})

        return {
            navigationType: digistormSettings.value.navType,
            ...navigationSet
        }
    })

    const defaultConfig = find(globalSets.value, { __typename: 'ds_Logos_GlobalSet' })

    const hasBannerImage = computed(() => {
        return entry.value?.banner?.length > 0
    })

    const navigationComponent = defineAsyncComponent(() =>
        import(`./${pageType}/${settings.value.navigationType}/nav.async.vue`)
    )

    const headerLogo = computed(() => {
        return defaultConfig?.logos_HeaderLogo
    })

    const stickyHeaderLogo = computed(() => {
        return defaultConfig?.logos_StickyNavLogo
    })

    const isPremier = computed(() => {
        return digistormSettings.value?.websiteTier === 'premier'
    })

</script>

<style lang="scss" scoped>

</style>
